import { render, staticRenderFns } from "./major-file-list.vue?vue&type=template&id=7d90b5f8&scoped=true&"
import script from "./major-file-list.vue?vue&type=script&lang=js&"
export * from "./major-file-list.vue?vue&type=script&lang=js&"
import style0 from "./major-file-list.vue?vue&type=style&index=0&id=7d90b5f8&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d90b5f8",
  null
  
)

export default component.exports